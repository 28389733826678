<template>
  <div>
    <color-text-btn v-p="['externaladmin:fedex:had:setDeclaration']" @click="setCustomDeclaration">设置报关标识</color-text-btn>
    <BaseDialog title="设置报关标识" :dialogVisible.sync="visible" width="30%">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="200">
        <el-form-item label="是否设置报关标识：" prop="pass">
          <el-radio-group v-model="radio">
            <el-radio :label="1">需要</el-radio>
            <el-radio :label="0">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </span>
    </BaseDialog>
  </div>
</template>

<script>
import { setCompanyDeclaration } from '@/api/expressAccount'
export default {
  props: {
    dialogData: {
      type: Object,
      default: () => ({})
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.radio = this.dialogData.isCustomsDeclare
      }
    }
  },
  data() {
    return {
      visible: false,
      radio: 0,
      ruleForm: {
        declaration: 0
      },
      rules: {
        declaration: [{ required: true, message: '请选择是否设置报关标识', trigger: 'change' }]
      }
    }
  },
  methods: {
    setCustomDeclaration() {
      this.visible = true
    },
    async onConfirm() {
      const data = {
        isCustomsDeclare: this.radio,
        id: this.dialogData.id
      }
      // 调用保存标识接口
      const { code } = await setCompanyDeclaration(data)
      if (code == 0) {
        this.visible = false
        this.sup_this.init()
        this.$message.success('报关标识设置成功')
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
